import Flickity from 'flickity'
import 'flickity/css/flickity.css'
import register from '../../util/init'
// import {toggle} from '../../util/tools'

class initPage {
  constructor (container) {
    this.container = container
    // init the listing gallery
    if (document.querySelector('.feature-gallery .gallery-items')) {
      this.initGallery(document.querySelector('.feature-gallery .gallery-items'))
    }
  }

  /**
  * display the listing as a flickity gallery
  *
  * @param {HtmlElement} galleryItems
  * @returns {object} flickityInstance
  */
  initGallery (galleryItems) {
    const options = {
      freeScrollFriction: 0.075,
      dragThreshold: 80,
      selectedAttraction: 0.025,
      friction: 0.28,
      cellAlign: 'left',
      contain: true,
      pageDots: true,
      prevNextButtons: false,
      on: {
        ready: function () {
          // if 'current-page' is not first set it
          // const index = document.querySelector(`#index .images .paginator .current-page`).getAttribute('data-page')
          // this.select(index - 1)
        },
        settle: (index) => {
          // recall the 'desktop' paginator
          // const current = document.querySelector(`#index .images .paginator [data-page='${index + 1}']`)
          // this.paginate(current, false)
        }
      }
    }
    let f = new Flickity(galleryItems, options)
    return f
  }
}
register('.preview-content', initPage)
