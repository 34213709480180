import register from '../../util/init'
import noUiSlider from 'nouislider'
import 'nouislider/distribute/nouislider.min.css'
import './nouislider_override.scss'
import InfScr from '../../util/infscr'
import { ajaxUrl } from '../../util/ajax_util'
import {addHandler} from '../../util/scroll_resize'
import ScrollBalance from '../../util/scrollbalance'
import {toggle} from '../../util/tools'
import {TABLET_PORTRAIT_MAX} from '../constants'

class initPage {
  constructor (container) {
    this.container = container
    // coninuous content loading
    this.initInfscr()
    // init scroll balance for requested instances
    window.scrollbalanceInstances = this.initScrollbalance(
      container.querySelectorAll('[data-request-scrollbalance]'))
    // content filters
    if (document.querySelector('#catalogue-filter-form')) {
      this.initFilters(document.querySelector('#catalogue-filter-form'))
    }
    if (document.querySelector('#catalogue-filter-nav')) {
      this.initFiltersNavigation(document.querySelector('#catalogue-filter-nav'))
    }
  }
  /**
  * handle content filtering here
  * 1. set up the price filter as a dragable slider - https://refreshless.com/nouislider/
  * 2. bind change events to submit the form
  * 3. toggle triggers for phone
  *
  * @param {form}, the filters form
  */
  initFilters (form) {
    // 1. init slider
    const slider = document.getElementById('price_filter')
    if (slider) {
      const minField = form.querySelector('[name=price_filter_min]')
      const maxField = form.querySelector('[name=price_filter_max]')

      const originalMin = parseInt(minField.getAttribute('data-min'))
      const originalMax = parseInt(maxField.getAttribute('data-max'))

      let slide = noUiSlider.create(slider, {
        start: [parseInt(minField.value), parseInt(maxField.value)],
        step: 10,
        range: {
          'min': [originalMin],
          'max': [originalMax]
        },
        connect: [false, true, false],
        tooltips: [true, true],
        format: {
          to: function (value) {
            // preserve int
            return parseInt(value)
          },
          from: function (value) {
            // preserve int
            return parseInt(value)
          }
        }
      })
      slide.on('set', () => {
        // set values when slide is released
        const values = slide.get()
        minField.value = parseInt(values[0])
        maxField.value = parseInt(values[1])

        handleSubmit()
      })
    }
    // 2. bind change events
    const handleSubmit = () => {
      // TODO v3 submit via ajax....?
      form.submit()
    }
    form.querySelectorAll('input').forEach((input) => {
      input.addEventListener('change', (e) => {
        handleSubmit()
      })
    })

    // 3. toggle triggers for phone
    form.querySelectorAll('.filter, .filter-master').forEach((filter) => {
      filter.querySelector('.filter-trigger').addEventListener('click', (e) => {
        e.preventDefault()
        toggle(filter, 'state-filter-open', () => {
          window.setTimeout(() => {
            if (window.scrollbalanceInstances) {
              window.scrollbalanceInstances.initialize()
            }
          }, 200)
        })
      })
    })
  }
  /**
  * a much simplier version of the above, for accessory catalogue page
  *
  * @param {filters}, the filters div
  */
  initFiltersNavigation (filters) {
    filters.querySelectorAll('.filter, .filter-master').forEach((filter) => {
      filter.querySelector('.filter-trigger').addEventListener('click', (e) => {
        e.preventDefault()
        toggle(filter, 'state-filter-open', () => {
          window.requestAnimationFrame(() => {
            if (window.scrollbalanceInstances) {
              window.scrollbalanceInstances.initialize()
            }
          })
        })
      })
    })
    window.requestAnimationFrame(() => {
      if (window.scrollbalanceInstances) {
        window.scrollbalanceInstances.initialize()
      }
    })
  }
  /**
  * setup an infinite scrolling page
  *
  * @method initInfscr
  * @return {function instance} InfScr
  */
  initInfscr () {
    const infscrInstance = InfScr(this.container.querySelector('.page'), {
      contentSelector: '.page-items',
      nextSelector: '.next-page',
      autoOffset: 1500,
      // varyOnCookies: [window.CURRENT_RANDOM_SEED],
      get_url: (link) => {
        return ajaxUrl(link.getAttribute('href'))
      },
      beforeLoad: () => {},
      onLoad: (newContent, container) => {
        if (window.scrollbalanceInstances) {
          // window.scrollbalanceInstances.initialize()
          const wSize = window.scrollbalanceInstances.windowSize()
          window.scrollbalanceInstances.resize(wSize.width, wSize.height)
        }
      }
    })
    addHandler({
      resize: (w, h) => {
        infscrInstance.resize(w, h)
      },
      scroll: (t, l) => {
        infscrInstance.scroll(t, l)
      }
    }, true)

    return infscrInstance
  }
  /**
  * init all requested scrollbalance instances
  * @function initScrollbalance
  * @param {HtmlElement} selector
  *
  */
  initScrollbalance (elements) {
    let si
    if (elements.length > 0) {
      si = new ScrollBalance(elements, {
        minwidth: TABLET_PORTRAIT_MAX
      })
      si.bind()
      si.initialize()

      // const handler = addHandler({
      //   teardown: () => {
      //     scrollbalanceInstances.unbind()
      //   }
      // })

      // add to teardown (for ajax page unload)
      // this.teardown.push(handler)
      return si
    }
  }
}
register('.catalogue-content', initPage)
