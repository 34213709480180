import register from '../../util/init'
import { gaPushCheckoutStep } from '../../util/google'

class initPage {
  constructor (container) {
    this.container = container
    this.cartForm = this.container.querySelector('.cart-form')

    if (this.container.querySelector('.save-details')) {
      this.bindSaveDetails(this.container.querySelector('.save-details'))
    }

    if (this.container.querySelector('[name="shipping_id"]')) {
      this.bindShippingSelect(this.container.querySelector('[name="shipping_id"]'))
    }

    this.analyticsEl = this.container.querySelector('[data-analytics-checkout-step]')
    this.analyticsCheckoutStepData = this.analyticsEl ? JSON.parse(this.analyticsEl.dataset.analyticsCheckoutStep) : null
    if (this.analyticsCheckoutStepData) {
      gaPushCheckoutStep(this.analyticsCheckoutStepData)
    }
  }
  /**
  * show / hide the password fields
  *
  * @param {HtmlElement} saveDetails
  */
  bindSaveDetails (saveDetails) {
    saveDetails.querySelector('[name="save-details"]').addEventListener('change', (e) => {
      if (saveDetails.classList.contains('state-enabled')) {
        saveDetails.classList.remove('state-enabled')
      } else {
        saveDetails.classList.add('state-enabled')
      }
    })
    // init state
    if (saveDetails.querySelector('[name="save-details"]:checked')) {
      saveDetails.classList.add('state-enabled')
    }
  }

  bindShippingSelect (select) {
    select.addEventListener('change', (e) => {
      this.cartForm.querySelector('[name="update_shipping"]').click()
    })
  }
}
register('.cart-content', initPage)


class paymentConfirmPage {
  constructor (container) {
    this.container = container
    this.paymentFormEl = this.container.querySelector('.payment-form')

    if (this.paymentFormEl) {
      this.initAfterpay()
    }

    this.analyticsEl = this.container.querySelector('[data-analytics-checkout-step]')
    this.analyticsCheckoutStepData = this.analyticsEl ? JSON.parse(this.analyticsEl.dataset.analyticsCheckoutStep) : null
    if (this.analyticsCheckoutStepData) {
      gaPushCheckoutStep(this.analyticsCheckoutStepData)
    }
  }

  initAfterpay () {
    const afterpayToken = this.paymentFormEl.getAttribute('data-afterpay-token')

    if (afterpayToken) {
      let afterpayInterval

      afterpayInterval = setInterval(() => {
        if (window.AfterPay) {
          clearInterval(afterpayInterval)
          window.AfterPay.initialize({countryCode: "NZ"})
          window.AfterPay.redirect({token: afterpayToken})
        }
      }, 100)
    }
  }
}
register('.payment-confirm-content', paymentConfirmPage)