/**
 * Call this function when a user clicks on a product link. This function uses the event
 * callback datalayer variable to handle navigation after the ecommerce data has been sent
 * to Google Analytics.
 *
 * IMPORTANT if we enable an ajax page load, remove the
 *           preventDefault and window.location
 */
export function gaPushSelectItem(e, anchor, productList) {
  if (!window.dataLayer) return;

  // e.preventDefault()

  try {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "select_item",
      ecommerce: Object.assign(
        {},
        productList[0] ? { item_list_name: productList[0].item_list_name } : {},
        {
          items: productList.map((productObj, index) => {
            return Object.assign({}, { index: productObj.index || index }, productObj);
          }),
        }
      ),
      // eventCallback: function () {
      //   window.location = anchor.href
      // }
    });
  } catch (error) {
    console.error(error);
    window.location = anchor.href;
  }

  // on local we don't actually trigger the
  // eventCallback so fallback in here
  // window.setTimeout(() => {
  //   window.location = anchor.href
  // }, 1000)
}

/*
 * Measure a view of product list.
 */
export function gaPushViewItemList(productList) {
  const tid = setInterval(function () {
    if (document.readyState !== "complete") return;
    clearInterval(tid);
    if (!window.dataLayer) return;
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "view_item_list",
      ecommerce: Object.assign(
        {},
        productList[0] ? { item_list_name: productList[0].item_list_name } : {},
        {
          items: productList.map((productObj, index) => {
            return Object.assign({}, { index: productObj.index || index }, productObj);
          }),
        }
      ),
    });
  }, 1000);
}

/*
 * Measure a view of product details.
 */
export function gaPushViewItem(productList) {
  const tid = setInterval(function () {
    if (document.readyState !== "complete") return;
    clearInterval(tid);
    if (!window.dataLayer) return;
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "view_item",
      ecommerce: {
        detail: {
          items: productList.map((productObj, index) => {
            return productObj;
          }),
        },
      },
    });
  }, 1000);
}

// Measure adding a product to a shopping cart
export function gaPushAddProductToCart(productList) {
  if (!window.dataLayer) return;
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: "add_to_cart",
    ecommerce: {
      items: productList.map((productObj, index) => {
        return productObj;
      }),
    },
  });
}

// Measure adding a product to a shopping cart
export function gaPushRemoveProductFromCart(productList) {
  if (!window.dataLayer) return;
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: "remove_from_cart",
    ecommerce: {
      items: productList.map((productObj, index) => {
        return productObj;
      }),
    },
  });
}

export function gaPushCheckoutStep(data) {
  const tid = setInterval(function () {
    if (document.readyState !== "complete") return;
    clearInterval(tid);
    if (!window.dataLayer) return;

    const events = Object.keys(data);
    events.forEach((e) => {
      const productList = data[e].items;
      delete data[e].items
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: e,
        ecommerce: Object.assign({}, data[e], {
          items: productList.map((productObj, index) => {
            return productObj;
          }),
        }),
      });
    });
  }, 1000);
}
