import {closest} from './tools'


export function updateLinkState (containers, url) {
  // Add current and child-current classes to links with the container,
  // depending on the current url
  // clear all

  const links = Array(...containers).reduce(
    (arr, el) => arr.concat(Array(...el.querySelectorAll('a[href]'))), [])
  url = url || (window.location.pathname)
  // specific to ducttape - child for listing
  url = url.replace('listing/', '')
  url = url.replace('latest/', '')

  // els.removeClass('current, child-current')
  links.forEach((el) => {
    const href = (el.getAttribute('href') || '').split('#')[0]

    if (!href) {
      // assume it's an anchor link
      return
    }

    if (href === url) {
      el.classList.add('current')
    } else {
      el.classList.remove('current')
    }

    if (url.indexOf(href) === 0 && href !== '/') {
      // specific to ducttape - prevent multiple child match
      const closestUl = closest(el, 'ul')
      if (closestUl) {
        const currentChild = closestUl.querySelector('.child-current')
        if (currentChild) {
          currentChild.classList.remove('child-current')
        }
      }
      el.classList.add('child-current')
      if (window.scrollbalanceInstances) {
        window.scrollbalanceInstances.initialize()
      }
    } else {
      el.classList.remove('child-current')
      if (window.scrollbalanceInstances) {
        window.scrollbalanceInstances.initialize()
      }
    }
  })
}
