import { post, displayErrors } from '../util/fetch'
import { sessionKey } from './index'
import { gaPushAddProductToCart } from '../util/google'

export function updateCart (cart) {
  // update header qty
  const qty = document.querySelector('#header .cart-link .qty')
  const lines = document.querySelector('#header .cart-tool .lines')
  const total = document.querySelector('#header .cart-tool .total')
  const renderLine = (line) => {
    const lineQuantity = line.quantity > 1 ? `x${line.quantity}` : ''
    return `<li>
              <span class='name'><h2>${line.description} ${lineQuantity}</h2></span>
              <a href="${window.URLS.CART}" class='edit'>Edit</a>
              <span class='price'>${line.total !== 0 ? '$' + line.total : 'Free'}</span>
            </li>`
  }
  qty.innerHTML = `x${cart.count}`
  // body class
  if (cart.count === 0) {
    document.body.classList.add('state-cart-empty')
    document.body.classList.remove('state-cart-has-items')
  } else {
    document.body.classList.remove('state-cart-empty')
    document.body.classList.add('state-cart-has-items')
  }
  // secret-floor content snippet
  if (cart.count === 0) {
    lines.innerHTML = ''
    total.innerHTML = ''
  } else {
    let cartLines = ''
    cart.lines.forEach((line) => {
      cartLines += renderLine(line)
      line.dependent.forEach((dependent) => {
        cartLines += renderLine(dependent)
      })
    })
    lines.innerHTML = cartLines
    total.innerHTML =
      `<span class='name'>Total</span> <span class='price'>$${cart.subtotal}</span>`
  }
}

export function updateWishlist (wishlist) {
  // update header qty
  const qty = document.querySelector('#header .wishlist-link .qty')
  const lines = document.querySelector('#header .wishlist-tool .lines')
  qty.innerHTML = `x${wishlist.count}`
  // body class
  if (wishlist.count === 0) {
    document.body.classList.add('state-wishlist-empty')
    document.body.classList.remove('state-wishlist-has-items')
  } else {
    document.body.classList.remove('state-wishlist-empty')
    document.body.classList.add('state-wishlist-has-items')
  }

  // secret-floor content snippet
  if (wishlist.count === 0) {
    lines.innerHTML = ''
  } else {
    let cartLines = ''
    wishlist.lines.forEach((line) => {
      const lineQuantity = line.quantity > 1 ? `x${line.quantity}` : ''
      cartLines +=
        `<li>
          <span class='name'><h2>${line.description} ${lineQuantity}</h2></span>
          <a href="${window.URLS.WISHLIST}" class='edit'>Edit</a>
          <span class='price'>$${line.total}</span>
        </li>`
    })
    lines.innerHTML = cartLines
  }
}

export function bindAddToCart (form) {
  const analytics = JSON.parse(form.dataset.analytics)
  const submit = () => {
    const url = `${form.getAttribute('action')}`

    let append = {'ajax': true}

    post(url, form, append).then((response) => {
      return response.json()
    }).then((data) => {
      if (Object.keys(data).length > 0) {
        if (data.success === false) {
          // handle error - specific error
          // form.querySelector('.error').innerHTML = data.errors
          form.classList.add('state-error')
          displayErrors(form, data.errors)
        } else {
          // SUCCESS
          // cart returns data not data.cart sooo catch all here
          updateCart(data.cart)

          // form states
          window.sessionStorage.removeItem(sessionKey)
          form.classList.add('state-added')
          form.classList.remove('state-error')
          window.setTimeout(() => {
            form.classList.remove('state-added')
          }, 2500)

          const formData = new window.FormData(form)
          const analyticsData = []
          for (const f of formData.entries()) {
            try {
              // match L-1234 : listing, W-1234 : warranty, A-1234 : accessory
              const match = analytics.find(
                a => a.item_id === `${f[0].toUpperCase().substring(0, 1)}-${f[1]}`)
              if (match) {
                analyticsData.push(match)
              }
            } catch (e) { console.log(e) }
          }

          
          if (analyticsData) gaPushAddProductToCart(analyticsData)

        }
      } else {
        // handle error - no error
        form.querySelector('.error').innerHTML = 'There was a problem'
        form.classList.add('state-error')
      }
    })
  }
  form.addEventListener('submit', (e) => {
    e.preventDefault()
    submit()
  })
}
