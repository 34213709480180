import docReady from 'doc-ready'

import {addHandler} from '../util/scroll_resize'
import {initContainers} from '../util/init'
import {updateLinkState} from '../util/link_state'
import {fetchData} from '../shoptools/index'
import {toggle, cutPaste} from '../util/tools'
import {selectStyles} from '../util/forms'
import {PHONE_MAX} from './constants'
import quickSearchInit, {clearSearch} from './shop/quicksearch'


export let overlayHandlers = []

docReady(() => {
  let setup = new SiteSetup()
  let init = new SiteInit()
  window.setTimeout(() => {
    // first up reveal (js only)
    document.body.style.opacity = null
    document.documentElement.classList.add('state-initialised')
  }, 100)
})

class SiteSetup {
  constructor () {
    // shoptools fetch
    fetchData()
    // close - anything that's registered to close when we hit the overlay
    document.querySelector('#overlay').addEventListener('click', (e) => {
      e.preventDefault()
      for (var i = 0, l = overlayHandlers.length; i < l; i++) {
        overlayHandlers[i]()
      }
    })
    // bind open/close of cart/wishlist
    this.bindSecretFloor()
    document.querySelectorAll('.quicksearch').forEach((form) => {
      quickSearchInit(form)
    })
    // reskin all select forms
    selectStyles(document.querySelectorAll('.select-mask select'))

    // shift elements on phone into a new position
    document.querySelectorAll('[data-cut-paste]').forEach((el) => {
      cutPaste(el)
    })
    // phone toggle navigation
    document.querySelector('#header .burger').addEventListener('click', (e) => {
      e.preventDefault()
      toggle(document.body, 'state-navigation-open', (isVisible, that) => {
        if (isVisible) {
          // make sure nothing else is visible
          document.body.classList.remove('state-secret-floor-open')
        }
      })
    })
    // bind close to overlay handler
    overlayHandlers.push(() => { document.body.classList.remove('state-navigation-open') })
  }
  /**
  * toggle all secret floors cart snippet etc
  *
  * @param {...}, .
  */
  bindSecretFloor () {
    const secretFloor = document.querySelector('.secret-floor')
    const collapseSecretFloor = (target) => {
      document.body.classList.remove('state-secret-floor-open')
      secretFloor.style.height = null
      if (target) {
        // specific remove
        // window.setTimeout(() => {
        //   target.classList.remove('transition')
        // }, 7000) // longer the css transition
      } else {
        // generic remove
        const trigger = document.querySelector('[data-secret-floor].open')
        if (trigger) {
          trigger.classList.remove('open')
        }
        const target = secretFloor.querySelector('.open')
        if (target) {
          target.classList.remove('open')
          target.classList.remove('transition')
        }
      }
    }
    // bind collapseSecretFloor to overlay click
    overlayHandlers.push(collapseSecretFloor)
    // toggle the secret-floor
    document.querySelectorAll('[data-secret-floor]').forEach((trigger) => {
      const targetSelector = `.${trigger.getAttribute('data-secret-floor')}`
      const target = document.querySelector(targetSelector)
      trigger.addEventListener('click', (e) => {
        e.preventDefault()
        toggle(target, 'open', (isVisible, that) => {
          if (isVisible) {
            // close navigation (phone)
            document.body.classList.remove('state-navigation-open')
            // close others
            const previousTrigger = document.querySelector(`.tools a.open`)
            const previousTarget = secretFloor.querySelector(`.open:not(${trigger.getAttribute('data-secret-floor')})`)
            if (previousTrigger && previousTarget) {
              previousTrigger.classList.remove('open')
              previousTarget.classList.remove('open')
              previousTarget.classList.remove('transition')
            }
            // open requested
            trigger.classList.add('open')
            target.classList.add('transition')
            document.body.classList.add('state-secret-floor-open')
            secretFloor.style.height = `${target.offsetHeight}px`
          } else {
            collapseSecretFloor()
          }
        })
      })
    })
  }
}

class SiteInit {
  // initialises newly loaded content
  // recallable for ajax loaded content
  constructor () {
    const current = document.querySelectorAll('#content > article')
    initContainers(current)
    updateLinkState(document.querySelectorAll('#header, #footer, #content'))
  }
}

// export const f = SiteSetup.prototype.f
