import {get, post} from '../../util/fetch'
// import {clearFilter} from './product_catalogue'
// import {clearSecondary} from './navigation'
// import {collapseUserTools} from './user_tools'
import formurlencoded from 'form-urlencoded'

// let searchForm

export function clearSearch (clearInput = true) {
  document.querySelectorAll('.quicksearch').forEach((searchForm) => {
    const input = searchForm.querySelector("input[type='search'], input[type='text']")
    const results = searchForm.querySelector('.results')

    document.body.classList.remove('state-search-open')
    if (clearInput) {
      input.value = ''
    }
    results.innerHTML = ''
  })
}

export default function quickSearchInit (form) {
  const input = form.querySelector("input[type='search'], input[type='text']")
  const results = form.querySelector('.results')
  const escape = form.querySelector('.escape')

  const action = `${form.getAttribute('action')}`
  const ajaxAction = `${form.getAttribute('data-ajax-action')}`

  let debounceSubmit
  // searchForm = form
  // submit form
  const submit = () => {
    document.body.classList.add('state-search-open')
    get(ajaxAction, {'search_filter': input.value}).then((response) => {
      return response.json()
    }).then((data) => {
      results.innerHTML = ''
      let resultsText = ''
      if (data.listings.length > 0) {
        resultsText += `<h2>Products</h2>`
        data.listings.forEach((result) => {
          resultsText += `<p><a href="${result.link}">${result.label}</a></p>`
        })
      }
      if (data.all) {
        resultsText += `<p><a href="${data.all}">View All</a></p>`
      }
      if (data.accessories.length > 0) {
        resultsText += `<h2>Accessories</h2>`
        data.accessories.forEach((result) => {
          resultsText += `<p class="accessory"><a href="${result.link}">${result.label}</a></p>`
        })
      }
      if (data.listings.length === 0 && data.accessories.length === 0) {
        resultsText = '<p>No results found</p>'
      }
      results.innerHTML = resultsText
      // input.focus()
    })
  }
  // bind focus/blur listener
  input.addEventListener('focus', (e) => {
    document.body.classList.add('state-search-focus')
  })
  input.addEventListener('blur', (e) => {
    document.body.classList.remove('state-search-focus')
  })
  // bind escape
  escape.addEventListener('click', (e) => {
    window.clearTimeout(debounceSubmit)
    clearSearch()
  })

  // bind keyup listener
  input.addEventListener('keyup', (e) => {
    // prevent to many call
    if (input.value.length === 0 || e.key === 'Backspace') {
      window.clearTimeout(debounceSubmit)
      clearSearch(false)
    } else if (input.value.length > 1 && (e.key !== 'Enter')) {
      window.clearTimeout(debounceSubmit)
      debounceSubmit = setTimeout(function () {
        // collapseUserTools({preventBlur: true})
        // clearFilter()
        // clearSecondary()
        submit()
      }, 300)
    }
  })
  input.addEventListener('keydown', (e) => {
    // ENTER KEY - send url to template
    if (e.key === 'Enter') {
      e.preventDefault()
      const data = formurlencoded({'search_filter': input.value})
      const url = `${action}?${data}`

      window.location = url
    }
  })
}
