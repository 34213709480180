/*

// NOTE this page is used for both listings and accessories

*/
import Flickity from 'flickity'
import 'flickity/css/flickity.css'
import register from '../../util/init'
import { toggle } from '../../util/tools'
import { gaPushViewItem } from '../../util/google'
import { bindAddToCart } from '../../shoptools/cart'

class initPage {
  constructor (container) {
    this.container = container
    // init the listing gallery
    if (document.querySelector('.listing-gallery .gallery-items')) {
      this.initGallery(document.querySelector('.listing-gallery .gallery-items'))
    }
    if (document.querySelector('.tech-spec-triggers')) {
      this.bindSpecToggle(document.querySelectorAll('.tech-spec-triggers a'))
    }
    // bind cart add submit (exclude sold out)
    const cartForm = container.querySelector('#buy-now-form')
    if (cartForm) {
      bindAddToCart(cartForm)
    }

    this.analyticsEl = this.container.querySelector('[data-analytics-view-item]')
    this.analyticsViewItemData = this.analyticsEl ? JSON.parse(this.analyticsEl.dataset.analyticsViewItem) : null
    if (this.analyticsViewItemData) {
      gaPushViewItem(this.analyticsViewItemData)
    }
  }
  /**
  * bind the spec triggers to open/close the related section
  *
  * @param {HtmlElement} triggers
  */
  bindSpecToggle (triggers) {
    triggers.forEach((trigger) => {
      const targetId = trigger.getAttribute('data-spec-id')
      const targets = document.querySelectorAll(`.tech-specs > div:not(#${targetId})`)
      const target = document.getElementById(targetId)
      trigger.addEventListener('click', (e) => {
        e.preventDefault()
        triggers.forEach((t) => { t.classList.remove('open') })
        targets.forEach((t) => { t.classList.remove('open') })
        toggle(target, 'open', (isVisible, that) => {
          // callback
          if (isVisible) {
            trigger.classList.add('open')
            // TODO v3 scroll to content (excoude header-height)
          } else {
            trigger.classList.remove('open')
          }
        })
      })
    })
  }

  /**
  * display the listing as a flickity gallery
  *
  * @param {HtmlElement} galleryItems
  * @returns {object} flickityInstance
  */
  initGallery (galleryItems) {
    const children = [...(galleryItems.children)]
      if (children.length > 1) {
      const options = {
        freeScrollFriction: 0.075,
        dragThreshold: 80,
        selectedAttraction: 0.025,
        friction: 0.28,
        cellAlign: 'left',
        contain: true,
        pageDots: true,
        prevNextButtons: false,
        on: {
          ready: function () {
            // if 'current-page' is not first set it
            // const index = document.querySelector(`#index .images .paginator .current-page`).getAttribute('data-page')
            // this.select(index - 1)
          },
          settle: (index) => {
            // recall the 'desktop' paginator
            // const current = document.querySelector(`#index .images .paginator [data-page='${index + 1}']`)
            // this.paginate(current, false)
          }
        }
      }
      let f = new Flickity(galleryItems, options)
      return f
    }
  }
}
register('.listing-content', initPage)
