import {closest} from './tools'
/**
 * Style tool for a select box
 *
 * @function selectStyles
 * @param {selectFields} selector
 * @param {options} object
 * @returns {object} left/right
**/
export function selectStyles (selectFields, options = {}) {
  let css = {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: '0',
    height: '100%',
    width: '100%',
    opacity: 0.01,
    background: 'none',
    zIndex: 1,
    border: 'none'
  }

  const {
    displayVal = (value, select) => {
      return value
    },
    classname = 'select-mask'
  } = options

  const setVal = (select, label) => {
    const value = select.querySelector('option:checked').innerHTML
    label.innerHTML = displayVal(value, select)
  }

  const handleFocus = (el, mask) => {
    el.addEventListener('focus', (e) => {
      mask.classList.add('focused')
    })
    el.addEventListener('blur', (e) => {
      mask.classList.remove('focused')
    })
  }

  selectFields.forEach((select) => {
    if (!select.getAttribute('data-select-mask-initialised')) {
      var mask = closest(select, '.' + classname)
      var label = document.createElement('span')
      mask.append(label)
      for (const c in css) {
        select.style[c] = css[c]
      }
      handleFocus(select, mask)
      setVal(select, label)
      select.addEventListener('change', (e) => { setVal(select, label) })
      select.setAttribute('data-select-mask-initialised', 'true')
    }
  })

  return selectFields
}
