import {updateCart, updateWishlist} from '../shoptools/cart'
import {get} from '../util/fetch'

const updaterRegistry = []
export const sessionKey = 'site-data'

export function registerUpdater (func) {
  /* Register an updater function which should run after fetching data. */
  updaterRegistry.push(func)
}

export function globalUpdate (data) {
  // set site state based on data
  if (data.account && data.account.user) {
    document.body.classList.add('state-authenticated')
    document.body.classList.remove('state-anonymous')
    // document.querySelector('.user-tools .account-name').html(`${data.account.first_name} ${data.account.last_name}`)
  } else {
    document.body.classList.add('state-anonymous')
    document.body.classList.remove('state-authenticated')
  }

  if (data.account && data.account.cart) {
    updateCart(data.account.cart)
  }
  if (data.account && data.account.wishlist) {
    updateWishlist(data.account.wishlist)
  }

  updaterRegistry.forEach((func) => {
    func(data)
  })
}

export function fetchData () {
  // TODO should just update based on the session data if it's there, but will
  // need to make sure it gets updated whenever globalUpdate is called - note
  // the data passed to globalUpdate may be incomplete, so the update needs to
  // be granular

  // for now, just run two updates - one on the session data and then another
  // once fresh data has been fetched

  const sessionData = JSON.parse(window.sessionStorage.getItem(sessionKey))

  function doUpdate (data) {
    globalUpdate(data)
    document.body.classList.add('state-data-loaded')
  }

  if (sessionData) {
    doUpdate(sessionData)
  }

  get(window.URLS.USER, {}).then((response) => {
    return response.json()
  }).then((data) => {
    window.sessionStorage.setItem(sessionKey, JSON.stringify(data))
    doUpdate(data)
  })
}
