import {addHandler, windowScroll, windowSize} from './scroll_resize'
import {PHONE_MAX} from '../src/constants'

let siteData = []

/**
 * Gets the top/left offset of an object in relation to the window
 *
 * @function getOffset
 * @param {HtmlElement} selector
 * @returns {object} left/right
**/

export function getOffset (el) {
  el = el.getBoundingClientRect()
  return {
    left: el.left + window.scrollX,
    top: el.top + window.scrollY
  }
}

/**
 * Finds the closest ancestor of any of el which matches selector.
 *
 * @function closest
 * @param {HtmlElement} selector
 * @returns {HtmlElement}
**/

export function closest (el, sel) {
  if (typeof el.closest === 'function') {
    return el.closest(sel) || null
  }
  while (el) {
    if (el.matches(sel)) {
      return el
    }
    el = el.parentElement
  }
  return null
}

/**
 * Basic class toggle method with callback
 *
 * @function toggle
 * @param {HtmlElement} el
 * @param {selector} className
 * @param {function} callback
 * @param {class/function} that parent (this)
 * @returns {HtmlElement}
 * @example
 * const sampleCallback = (bool) => {
 *   alert(bool)
 * }
 * toggle(document.body, 'hello-world', sampleCallback)
**/

export function toggle (el, className, callback, that) {
  let bool
  if (!el.classList.contains(className)) {
    el.classList.add(className)
    bool = true
  } else {
    el.classList.remove(className)
    bool = false
  }
  if (callback) {
    return callback(bool, that)
  }
}

/**
 * Selects the next item in a nodelist,
 * if there is none return the first in list
 *
 * @function next
 * @param {HtmlElement} selector
 * @param {bool} loop
 * @returns {HtmlElement} next or first
**/

export function next (el, loop = true) {
  if (loop) {
    return el.nextElementSibling ? el.nextElementSibling : el.parentElement.firstElementChild
  } else {
    return el.nextElementSibling
  }
}

/**
 * Selects the prev item in a nodelist,
 * if there is none return the last in list
 *
 * @function prev
 * @param {HtmlElement} selector
 * @param {bool} loop
 * @returns {HtmlElement} next or first
**/

export function prev (el, loop = true) {
  if (loop) {
    return el.previousElementSibling ? el.previousElementSibling : el.parentElement.lastElementChild
  } else {
    return el.previousElementSibling
  }
}

/**
 * Shift the location of a DOM element at a certain breakpoint
 * e.g. cut element
 *      <div class="speakers tablet-plus-only" data-cut-paste='{"location": "aside .details .speakers", "breakpoint": "phone"}'>
 *      paste element
 *      <div class="speakers phone-only"></div>
 *
 * @function cutPaste
 * @param {HtmlElement} selector
**/

export function cutPaste (el) {
  const breakpointMap = {
    // TODO add more as needed
    'phone': (w) => { return w <= PHONE_MAX }
  }
  const data = JSON.parse(el.getAttribute('data-cut-paste'))
  const cut = el
  const paste = document.querySelector(data.location)
  const breakpointQuery = breakpointMap[data.breakpoint]
  const children = [...(el.children)]
  let current = cut
  addHandler({
    resize: (w, h) => {
      if (breakpointQuery(w) && current === cut) {
        // move to the requested location
        children.forEach((child) => { paste && paste.appendChild(child) })
        cut.innerHTML = ''
        current = paste
      } else if (!breakpointQuery(w) && current === paste) {
        // return to the original location
        children.forEach((child) => { cut && cut.appendChild(child) })
        paste.innerHTML = ''
        current = cut
      }
    },
    scroll: (t, l) => {}
  }, true)
}

/**
 * Get/Set data on an element
 * NOTE siteData established at top
 *
 * @function data
 * @param {HtmlElement} el
 * @param {String} ref (reference name)
 * @param {String} data (function/object (usually containing a function))
 * @return {Function} guid
**/

export function data (el, ref, data) {
  let guid
  const makeGuid = () => {
    /* NOTE - not actually unique, but chance of a clash is tiny. */
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8)
      return v.toString(16)
    })
  }
  if (data) {
    // set
    guid = el.setAttribute(`data-${ref}`, makeGuid())
    siteData[guid] = data
  } else {
    // get
    guid = el.getAttribute(`data-${ref}`)
  }
  return siteData[guid]
}

/**
 * add subtle parallax movement to an element,
 * @function parallax
 * @param  {Array} el               element to move
 * @param  {Number} [factor=0.5]     controls effect amount
 * @param  {Number} [mobileFactor=0]     controls effect amount on mobile
 * @return {Object} object with resize and scroll props
 * TODO - handle relative/fixed differently
 */

export function parallax (el, options = {}) {
  const {
    factor = 0.5,
    mobileFactor = 0
  } = options

  let trigger
  let winWidth
  let initialised = false
  let resizeTimer

  const resize = (ww, wh) => {
    const transform = el.style.transform.match(/translateY\((-?[\d.]+)px\)/)
    trigger = getOffset(el).top
    // trigger = 0

    if (transform) {
      trigger -= parseInt(transform[1])
    }

    winWidth = ww
    clearTimeout(resizeTimer)
    resizeTimer = window.setTimeout(() => {
      const ws = windowScroll()
      scroll(ws.top, ws.left)
    }, 250)
  }

  const scroll = (st, sl) => {
    // relative
    // const shift = (trigger - st) *
    //   (winWidth > PHONE_MAX ? factor : mobileFactor)
    // fixed
    const shift = (0 - st) *
      (winWidth > PHONE_MAX ? factor : mobileFactor)

    if (!initialised) {
      el.classList.add('parallax-initialised')
      initialised = true
    }

    // DISABLED limit to avoid unnecessary DOM updates
    // if (shift < winHeight && shift > -winHeight) {
    let transform = 'translateY(' + shift + 'px) translateZ(0)'
    el.style[window.Modernizr.prefixed('transform')] = transform
    // }
  }

  addHandler({
    resize: resize,
    scroll: scroll
  }, true)
}

/**
 * while scrolling adjust a percent or decimal value
 * according to a factor of the scroll, values will only vary from
 * 0-100 (percent) or 0-1 (decimal)
 * @function relativeScroll
 * @param  {Array} el               element to move
 * @param  {Decimal} [factor=0.5]     controls effect amount
 * @param  {Decimal} [mobileFactor=0]     controls effect amount on mobile
 * @param  {Decimal} [triggerPoint=0]     scroll position to start (percent of window)
 * @param  {boolean} [isDecimal=false]     percent/decimal
 * @param  {string} [property='transform']   eg. el.style[property]

 * @return {Object} object with resize and scroll props
 * TODO - bit hairy with triggerpoint + factor ??
 *        allow for transform
 */

export function relativeScroll (el, options = {}) {
  const {
    factor = 0.5,
    mobileFactor = 0,
    triggerPoint = 0,
    isDecimal = false,
    property = 'transform'
  } = options

  let trigger
  let winWidth
  let initialised = false
  let resizeTimer

  const min = 0
  const max = 100

  const resize = (ww, wh) => {
    trigger = windowSize().height * triggerPoint
    winWidth = ww
    clearTimeout(resizeTimer)
    resizeTimer = window.setTimeout(() => {
      const ws = windowScroll()
      scroll(ws.top, ws.left)
    }, 250)
  }

  const scroll = (st, sl) => {
    let shift = (st - trigger) *
      (winWidth > PHONE_MAX ? factor : mobileFactor)

    shift = Math.min(shift, max)
    shift = Math.max(shift, min)

    if (!initialised) {
      el.classList.add('scroll-relative-initialised')
      initialised = true
    }
    // if (shift >= min && shift <= max) {
    let transform = isDecimal ? (shift / max) : `${shift}%`
    el.style[window.Modernizr.prefixed(property)] = transform
    // }
  }

  addHandler({
    resize: resize,
    scroll: scroll
  }, true)
}

/**
 * Just wait to show image untill it's loaded.
 * return ... adds suitable classes to elements
 *
 * @param {HtmlElement} .image
**/

export function lazyLoad (el) {
  if (!el.classList.contains('lazyloading') &&
      !el.classList.contains('lazyloaded')) {
    const im = el.querySelector('img')
    const src = im.getAttribute('data-src')
    const srcset = im.getAttribute('data-srcset')

    el.classList.add('lazyloading')

    if (src) {
      im.setAttribute('src', src)
      im.removeAttribute('data-src')
    }
    if (srcset) {
      im.setAttribute('srcset', srcset)
      im.removeAttribute('data-srcset')
    }

    im.addEventListener('load', function (e) {
      el.classList.remove('lazyloading')
      el.classList.add('lazyloaded')
    })
  }
}

/**
 * add/remove a class at a specific scroll point
 * all resize control is handled here
 * fixed/unfixed classes get a width applied to element, to prevent 'scroll bar jumps'
 *
 * @param {HtmlElement/number} trigger
 * @param {string} className
 * @param {boolean} remove (do you want to remove the class again?)
 * @param {selector} applyClass (can be body or selector)
 * @param {decimal} buffer (a percent of the screen height eg. .5 = half)
 * @param {boolean} allowMobile (do you want it to track on mobile?)
**/

export function scrollPointTrigger (trigger, options = {}) {
  const {
    className = 'fixed',
    remove = true,
    applyClass = trigger,
    buffer = false,
    allowMobile = false
  } = options

  let triggerPoint
  let enableTransitions = false
  let currentWindowHeight
  let triggerWidth
  let reapplyClass = false
  addHandler({
    resize: function (winWidth, winHeight) {
      if (applyClass.classList.contains(`scroll-${className}`)) {
        // strip class, to recalculate
        applyClass.classList.remove(`scroll-${className}`)
        reapplyClass = true
      }
      enableTransitions = allowMobile ? true : winWidth > PHONE_MAX
      currentWindowHeight = winHeight
      triggerPoint = isNaN(trigger) ? getOffset(trigger).top : trigger
      // reset width (see function note above)
      trigger.style.width = null
      if (winWidth > PHONE_MAX && className === 'fixed') {
        triggerWidth = trigger.clientWidth
        trigger.style.width = `${triggerWidth}px`
      }
      if (reapplyClass) {
        // if we had the class applied - re-apply it
        applyClass.classList.add(`scroll-${className}`)
        reapplyClass = false
      }
    },
    scroll: function (scrollY, scrollX) {
      if (enableTransitions) {
        const triggerBuffer = buffer ? scrollY + (currentWindowHeight * buffer) : scrollY
        if (triggerPoint < triggerBuffer) {
          applyClass.classList.add(`scroll-${className}`)
        } else {
          if (remove) {
            applyClass.classList.remove(`scroll-${className}`)
          }
        }
      }
    }
  }, true)
}
